@import url('https://fonts.cdnfonts.com/css/copperplate');
/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap'); */
@import url('https://fonts.cdnfonts.com/css/earth-orbiter');

@font-face {
    font-family: Josefin Sans;
    src: url(./helpers/Josefin_Sans/JosefinSans-VariableFont_wght.ttf);
}

:root {
    --color1: #cb7400;
    --color2: #dd7f01;
    --color3: #de9917;
    --color4: #f5b332;
    --color5: #e6d88e;
    --heading-family: 'Copperplate', serif;
    --body-family: 'Josefin Sans', sans-serif;
}

h1 {
    font-family: var(--heading-family);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--color3) !important;
    font-weight: 300 !important;
    font-variation-settings: "wght" 300;
}

p {
    font-size: 1.2rem;
}

p a, span a {
    color: var(--color3) !important;
    text-underline-offset: 2px;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black !important;
    /* color: #c79645 !important; */
    color: var(--color3) !important;
    font-family: var(--body-family) !important;
    font-weight: 300 !important;
    font-variation-settings: "wght" 300;
    overflow-x: hidden !important;
}

section {
    margin-top: 8rem;
}

.blog-img {
    max-width: 20rem;
    max-height: 20rem;
    cursor: pointer;
}

.blog-overview {
    margin-left: 2rem;
    margin-right: 2rem;
}

.blog-title {
    width: 70%;
}

.responsive-img {
    width: 100%;
    max-width: 250px;
    height: auto;
}

.a-link {
    color: var(--color3) !important;
    text-underline-offset: 6px;
    text-decoration: underline;
}

.btn-primary,
.swal2-styled {
    background-color: var(--color3) !important;
    border-color: var(--color3) !important;
    color: black !important;
    transition: transform 0.3s ease-in-out !important;
    box-shadow: none !important;
}

.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:active:focus:not(:disabled):not(.disabled) {
    background-color: var(--color1) !important;
    border-color: var(--color1) !important;
    box-shadow: none !important;
    -webkit-tap-highlight-color: var(--color3) !important;
}

.btn-primary:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
}

.aboutContainer {
    height: 75vh;
    width: 75vw;
    position: relative;
    background-image: url(./images/minified/samihula2.JPG);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 550px;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.aboutCreators {
    max-width: 50vw;
    max-height: 50vh;
}

.aboutText {
    max-width: 80%;
    margin-top: 2rem;
}

.aboutTextOverlay {
    position: relative;
    background-color: rgb(56, 56, 56);
    z-index: 1;
    margin-left: 33rem;
    max-height: 70vh;
    max-width: 30vw;
    border-radius: 8px;
    padding: 30px;
    text-align: left;
    font-size: large;
    margin-top: -42rem;
}

.bg-black {
    background-color: black;
    transition: transform 0.3s ease-in-out !important;

}

.navLink.active {
    text-decoration: underline !important;
    text-underline-offset: 4px;
    text-decoration-thickness: 1.5px !important;
}

.banner-wrapper {
    overflow: hidden;
    height: 60vh !important;
    background-image: url(./images/minified/risingPhoenixSplash.JPG);
    background-size: contain;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 55%;
}

.banner-img {
    width: 100%;
}

.box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.card {
    min-height: 300px;
}

.card-text {
    padding-top: .5rem;
    padding-bottom: .25rem;
}

.card-title {
    font-family: var(--heading-family);
    font-size: 1.8rem;
    border-bottom: .001px solid whitesmoke;
    padding-bottom: .5rem;
}

.card-footer {
    /* border-top: .001px solid whitesmoke !important; */
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.colored-text {
    color: var(--color3) !important;
}

.colored-text-black {
    color: black !important;
}

.contact-image {
    max-height: 600px;
    max-width: 90vw;
    order: 1;
}
.contact-content {
    order: 2;
}

.contentStrip {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 75vw;
    margin-top: 8rem;
}

.vcenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

#dropdown-split {
    background-color: transparent !important;
    border-color: transparent !important;
}

#dropdown-split:hover {
    background-color: transparent !important;
}

.fb-icon {
    transform: scale(0.9) !important;
}

.featured {
    /* min-width: 50vw !important; */
}

.dropdown-menu {
    background-color: rgb(33,37,41) !important;
}

.dropdown-item {
    color: white !important;
}

.dropdown-item:hover {
    background-color: rgb(78, 86, 94) !important;
}

.dropdown-item.active {
    /* background-color: rgb(78, 86, 94) !important; */
    background-color: var(--color3) !important;
    color: black !important;

}

#footer {
    padding-top: 5rem;
    padding-bottom: 1.5rem;
}

.footer-wrapper {
    display: flex;
    flex-direction: column;
}

.footer-icon-wrapper {
    /* padding-left: 10px;
    padding-right: 10px; */
    display: flex;
    justify-content: space-around;
}

.footer-icon {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    max-height: 40px;
    width: auto;
}

.gigsalad-footer-icon {
    transform: scale(0.8);
}

.gigsalad img {
    width: 80px;
}

.gradient {
    background-image: -webkit-linear-gradient(var(--color3), black);
}

.hamburger-menu {
    position: absolute;
    left: 80%;
    top: .75rem;
    z-index: -1;
    padding: 1em;
}

.homeBlurb {
    line-height: 2.5rem;
    text-align: center;
}

.homeContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    max-width: 60vw;
    transform: translate(130%, -95%);
    z-index: 2;
}

.heading {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeTriplet {
    max-height: 35vh;
    max-width: 35vw;
    /* max-width: 100%; */
    /* height: auto; */
}

.homeTripletContainer {
    overflow-x: hidden;
    overflow-y: hidden;
}

.img-wrapper {
    max-height: 50vh;
}

.indent {
    text-indent: 15%;
    margin-top: 1.5rem;
}

.indent-list {
    margin-left: 3rem;
}

.iframeContainer {
    display: flex;
}

.iframeCenter {
    align-content: center;
}

.membership {
    width: 75%;
    background-color: rgb(56, 56, 56);
    border-radius: 8px;
    padding: 30px;
}

.meetCreators {
    padding: 3rem;
    margin-top: 10rem;
}

.navBrand {
    font-family: var(--heading-family) !important;
    font-weight: 700;
}

#nav-dropdown-black {
    color: black !important;
}

#nav-dropdown-colored {
    color: var(--color3) !important;
}

#nav-dropdown-colored.active,  #nav-dropdown-black.active{
    text-decoration: underline !important;
    text-underline-offset: 4px;
    text-decoration-thickness: 1.5px !important;
}

.navbar-toggler {
    border: none !important;
}
.navbar-toggler:focus {
    box-shadow: none !important;
}

.navbar-toggler-icon {
    background-image: none !important;
}

.navLink {
    font-weight: 400;
    font-variation-settings: "wght" 400;

}

.pricetag {
    position: relative;
    display: inline-block;
    width: auto;
    height: 20px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 5px;
    background: #e20a0a;
    color: white;
    line-height: 20px;
    border-radius: 4px;
    border: 1px solid #bc0a0a;
    font-size: .85rem;
  }
  .pricetag:after,
  .pricetag:before {
    position: absolute;
    content: '';
    border: 1px solid #bc0a0a;
  }
  .pricetag:after {  /* the arrow on left side positioned using left property */
    height: 14px;
    width: 14px;
    transform: rotate(45deg);
    background: #e20a0a;
    border-color: transparent transparent #bc0a0a #bc0a0a;
    left: -6px;
    top: 2px;
  }
  .pricetag:before {  /* the circle on the left */
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: white;
    left: 0px;
    top: 6px;
    z-index: 2;
  }

.quote {
    line-height: 3.5rem;
}

.quoteStrip {
    max-width: 85vw;
    margin-top: 5rem;
    margin-bottom: 4rem;
}

.rising-blurb {
    line-height: 2.5rem;
    text-align: center;
    margin: 1rem;
}

.signature {
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
    font-family: 'Earth Orbiter';
    font-size: 1.1rem;
}

#footer a {
    text-underline-offset: 4px !important;
}

.show .navbar-nav {
    padding-bottom: 3rem;
}

.splashImage {
    width: 90vw;
    height: 90vh;
    display: block;
}

.splashWrapper {
    margin-top: 4rem;
}

.serviceBlurb {
    line-height: 2.5rem;
    text-align: center;
    margin: 1rem;
    max-width: 75vw;
}

.serviceImg {
    margin-top: 2rem;
    width: 100%;

}

.serviceTriplet {
    max-height: 30vh !important;
    max-width: 20vw !important;
    /* max-width: 80%; */
    /* height: auto; */
}

.swal2-title {
    color: black !important;
}

.tagLine {
    font-family: var(--heading-family);
    /* width: 100vw; */
    text-align: center;
}

.tagBlurb {
    max-width: 40vw;
    line-height: 2.5rem;
}

.testimonial-author {
    margin-top: 2rem;
}

.testimonial-author-wrapper {
    max-width: 75vw;
}

.testimonial-header {
    /* padding-bottom: 1rem; */
}

.testimonial-card {
    /* height: auto; */
    height: 46vh !important;
    color: var(--color3) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.testimonial-card-wrapper{
    max-width: 75vw;
}

#iframe {
    background-color: black !important;
    border-radius: 9px;
}

.workbook-carousel > .carousel-control-prev {
    /* margin-left: calc(100vw + 0); */
}
.workbook-carousel > .carousel-control-next {
    /* margin-right: -200px; */
}

.workbook-page {
    transform: scale(0.6);
}

/* big screens */
@media screen and (min-width: 1501px) {
    .serviceTriplet {
        max-height: 40vh;
        max-width: 30vw;
    }

    .homeTriplet {
        max-height: 40vh;
        max-width: 30vw;
    }

    .testimonial-card {
        height: 34vh;
    }
    .carouselSlide {
        margin-bottom: 2rem;
    }
    .workbook-carousel > .carousel-indicators {
        margin-bottom: -1rem;
    }
    .testimonial-card-wrapper, .testimonial-author-wrapper {
        max-width: 40vw;
    }
}

@media screen and (min-height: 1500px) {
    .serviceTriplet {
        max-height: 27vh !important;
        max-width: 28vw !important;
    }
}

/* smaller laptops */
@media screen and (min-width: 1201px) and (max-width: 1500px) {
    .aboutTextOverlay {
        position: relative;
        background-color: rgb(56, 56, 56);
        z-index: 1;
        margin-left: 52%;
        max-height: 90vh;
        max-width: 30vw;
        border-radius: 8px;
        padding: 30px;
        text-align: left;
        font-size: large;
        margin-top: -34rem;
    }

    .aboutContainer {
        height: 75vh;
        width: 75vw;
        margin-left: 20%;
        position: relative;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: 440px;
        background-repeat: no-repeat;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .homeContent {
        transform: translate(135%, -84%);

    }

    .workbook-carousel > .carousel-indicators {
        margin-bottom: -1rem;
    }

    .carouselSlide {
        margin-bottom: 4.5rem;
    }

    .testimonial-header {
        padding-bottom: 0.1rem;
    }

    .testimonial-card {
        height: 40vh;
    }

    .testimonial-card-wrapper > .testimonial-card-content {
        font-size: 1.3rem !important;
        margin-top: 3rem !important;
    }

    .testimonial-author-wrapper > .testimonial-author {
        font-size: 1.3rem !important;
    }

    .serviceTriplet {
        max-height: 36vh;
        max-width: 30vw;
        margin-bottom: 1rem;
    }

    .homeTriplet {
        max-height: 36vh;
        max-width: 30vw;
    }
}

/* ipad screen and lower */
@media screen and (max-width: 1200px) {
    .homeContent {
        position: relative;
        display: flex;
        max-width: 100vw;
        transform: translate(0%, 0%);
        justify-content: center;
        margin-top: 2rem;
    }

    .serviceTriplet {
        max-height: 36vh;
        max-width: 30vw;
        margin-bottom: 1rem;
    }

    .homeTriplet {
        max-height: 34vh;
        max-width: 28vw;
    }

    .contentStrip {
        margin-top: 2rem;
    }

    .tagBlurb {
        min-width: 80vw !important;
    }

    .aboutTextOverlay {
        position: relative;
        margin: 0rem;
        max-width: 90vw;
    }
    .aboutWrapper {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    .aboutContainer {
        background-position: center;
        margin-right: 0 !important;
    }

    .aboutImgWrapper {
        display: flex;
        justify-content: center;
    }

    .meetCreators {
        margin-top: 1rem;
    }

    .footer-text {
        font-size: medium;
    }

    .gigsalad,
    .icons {
        transform: scale(0.9);
    }

    .testimonial-card {
        height: 38vh;
    }

    .testimonial-header {
        /* padding-bottom: 0.5rem; */
    }
    .testimonial-card-content {
        max-width: 80vw;
        font-size: 1.2rem !important;
        margin-top: 1.75rem;
    }

    .testimonial-author {
        max-width: 80vw;
        /* font-size: 1.2rem !important; */
    }

    .testimonial-card-wrapper > .testimonial-card-content {
        font-size: 1.2rem !important;
        margin-top: 3rem !important;
    }

    .testimonial-author-wrapper > .testimonial-author {
        font-size: 1.2rem !important;
    }

    .carouselSlide {
        margin-bottom: 3rem;
    }

    .carousel-indicators {
        margin-bottom: -1rem !important;
    }
    .signature {
        font-size: 0.8rem;
    }
}

/* older ipads */
@media screen and (max-height: 1100px) and (max-width: 768px) {
    .serviceTriplet {
        max-height: 12vh !important;
        max-width: 20vw;
    }
}

@media screen and (max-height: 900px) and (max-width: 1199px) {
    .serviceTriplet {
        max-height: 22vh;
        max-width: 20vw;
    }
    .homeTriplet {
        /* max-width: 25vw; */
        /* max-height: 22vh; */
    }
    .signature,
    .footer-text {
        font-size: 0.7rem;
    }

    .aboutCreators {
        transform: scale(0.9);
    }
}

/* mobile screen */
@media screen and (max-width: 768px) {
    .banner-wrapper{
        background-size: 100%;
        max-height: 40vh;
    }

    .blog-title {
        width: 90%;
    }

    .indent-list {
        margin-left: 2rem;
    }

    .featured {
        width: 100% !important;
    }

    .contentStrip {
        margin-top: 8rem;
    }

    .serviceBlurb {
        max-width: 90vw;
    }

    .splashImage {
        margin-left: 12vw;
        transform: scale(1.5);
        margin-bottom: 2rem;
    }

    .splashWrapper {
        margin-top: 2rem;
    }

    .splashWrapper {
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .homeTripletContainer {
        max-width: 54vw;
    }

    .homeTriplet {
        max-width: 100%;
        max-height: 30vh;
        transform: scale(1.3);
        margin-bottom: 4rem;
    }

    .homeBlurb {
        margin-top: -6rem;
    }

    .aboutTextOverlay {
        margin-top: 2rem;
        margin-bottom: 2rem;
        max-height: 100vh;
    }
    .serviceTriplet {
        transform: scale(2.95);
        margin-bottom: 6rem;
        margin-top: 4rem;
    }
    .testimonial-card {
        height: 68vh !important;
    }
    .testimonial-header {
        /* padding-bottom: -2rem; */
    }

    .footer-text {
        font-size: 0.55em !important;
    }
    .signature {
        font-size: 0.53em !important;
    }
    .gigsalad,
    .icons {
        transform: scale(0.7);
    }
    .gradient {
        background-image: -webkit-linear-gradient(var(--color3), black);
    }

    .tagBlurb {
        min-width: 90vw !important;
    }
    /* .testimonial-card-content {
        margin-top: 1rem;
    } */

    .testimonial-card-content,
    .testimonial-author {
        max-width: 80vw;
        /* font-size: .1rem !important; */
    }

    .testimonial-carousel .carousel-indicators{
        margin-bottom: -.75rem !important;
    }

    .aboutCreators {
        margin-top: 2rem;
    }

    .membership,
    .aboutContainer {
        width: 90vw !important;
    }

    .tagLine {
        font-size: 2.5rem;
        width: 100vw;
        text-align: center;
    }
}
/* small phones */
@media screen and (max-width: 380px) {
    .tagLine {
        font-size: 1.75rem;
        width: 100vw;
        text-align: center;
    }

    .testimonial-carousel > .carousel-indicators {
        margin-bottom: 4rem !important;
    }


    .testimonial-card-content {
        margin-top: 2rem !important;
    }

    div .testimonial-card-content,
    div .testimonial-author {
        font-size: 1rem !important;
    }
    .aboutTextOverlay {
        margin-top: 2rem;
        margin-bottom: 2rem;
        max-height: 140vh;
    }
    .meetCreators {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .serviceTriplet {
        transform: scale(2.35);
    }
    .homeTriplet {
        max-width: 65vw;
        max-height: 70vw;
        transform: scale(1.05);
    }
    .carouselSlide {
        margin-bottom: 4rem;
    }
    .aboutCreators {
        max-height: 45vh;
        max-width: 45vh;
    }
    .aboutContainer {
        background-position: center;
        margin-right: 0 !important;
        background-size: 140%;
    }
    .serviceBlurb {
        min-width: 90vw !important;
        font-size: 1.1rem;
    }
}

@media screen and (max-height: 450px) and (max-width: 975px) {
    .aboutTextOverlay {
        max-height: 150vh !important;
    }
    .aboutContainer {
        background-size: 60%;
        height: 120vh;
    }
    .aboutCreators {
        max-height: 85vh;
        max-width: 65vh;
    }
    .testimonial-card-content,
    .testimonial-author {
        font-size: 0.9rem !important;
    }
    .carouselSlide {
        margin-bottom: 4rem;
    }
}
